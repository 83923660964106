import logo from './images/logo.png'
import under from './images/under.png'
import circle from './images/circle.png'
import { Mailchimp, Footer } from '..';

function Home() {
  return (
    <div className="wrapper">
      <div className="wrapper-logo">
        <img src={logo} alt="passa" />
      </div>
      <div className="wrapper-title">
        <h1>
            <span className="wrapper-title-under">
                <img src={under} alt="under" />
                Calma 
            </span> que a virgiana <br/> está trabalhando <span className="wrapper-title-circle">
                <img src={circle} alt="circle" />
                 no site novo
            </span>
        </h1>
      </div>
      <div className="mailchimp-code">
        <Mailchimp />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
