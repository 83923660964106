function Mailchimp() {
    return (
        <div>
            {/* Begin Mailchimp Signup Form */}
            <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
            <style type="text/css" dangerouslySetInnerHTML={{__html: "\n#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}\n/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.\n  We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */\n" }} />
            <div id="mc_embed_signup" style={{width: '100%'}}>
            <form action="https://passa.us17.list-manage.com/subscribe/post?u=0dec38e69f5b757bdf7885d82&id=3edaa06df9&f_id=00ea4be0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll" style={{width: '75%', margin: '0 auto', minWidth: '300px'}}>
                <div className="mc-field-group" style={{display : 'flex'}}>
                    <input type="email" placeholder="Insira seu email aqui" name="EMAIL" className="required email" id="mce-EMAIL" required style={{fontFamily : 'Helvetica', fontWeight: 'bold', padding: 0, height: '30px', marginRight: '8px'}}/>
                    <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" style={{backgroundColor: '#57cbfc', color : "#000", fontFamily : 'Helvetica', fontWeight: 'bold', display : 'flex', justifyContent: 'center', alignItems : 'center', padding : 0, maxWidth: '90px', borderRadius: '4px'}} />
                </div>
                <div id="mce-responses" className="clear foot">
                    <div className="response" id="mce-error-response" style={{display: 'none'}} />
                    <div className="response" id="mce-success-response" style={{display: 'none'}} />
                </div>    {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_0dec38e69f5b757bdf7885d82_3edaa06df9" tabIndex={-1} defaultValue /></div>
                <div className="optionalParent">
                    <div className="clear foot" style={{ display: 'flex', justifyContent: 'flex-end'}} >
                    
                    </div>
                </div>
                </div>
            </form>
            </div>
            {/*End mc_embed_signup*/}
        </div>
    )
}

export default Mailchimp