import { Home } from './components';

import './styles/reset.css';
import './styles/App.css';

function App() {
  return <Home />;
}

export default App;
