import { FaInstagram, FaYoutube, FaTwitter, FaLinkedin, FaTiktok } from 'react-icons/fa';

function Footer() {
    const medias = [
        {
            href : 'https://www.instagram.com/passa',
            logo : <FaInstagram />
        },
        {
            href : '#',
            logo : <FaYoutube />
        },
        {
            href : 'https://twitter.com/passa',
            logo : <FaTwitter />
        },
        {
            href : 'https://www.linkedin.com/in/ana-paula-passarelli-creator-marketing-influencia/',
            logo : <FaLinkedin />
        },
        {
            href : 'https://www.tiktok.com/@passa',
            logo : <FaTiktok />
        },
    ]

    return (
        <ul className="footer">
            {medias.map((item) => 
                <li>
                    <a href={item.href} target="_blank">
                        {item.logo}
                    </a>
                </li>
            )}
        </ul>
    )
}

export default Footer